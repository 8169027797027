//fonts
$primaryFont:'Montserrat', sans-serif;
$bold:'Montserrat', sans-serif;
// $light:'Euclid Circular A Light

//colors
$primary:#083563;
$blue:#083563;
$green:#03EF62;
$white:#ffffff;
$black:#04192C;
$bg: #FFFBF2;
$grey-4:#C4C4C4;




$dark:#212121;
$secondary:#00114D;
$nearWhite:#FAFAFA;
$grey-c:#cccccc;
$grey-3:#333333;
$grey-9:#999999;
$grey-6:#666666;
$purple:#E5EBFF;
$border: 1px solid #E6E6E6;



