.pills {
  padding: 0 5rem;
  padding-right: 7rem;
  border: 0.3px solid rgba(2, 8, 14, 0.5);
  border-top-color: transparent;
  border-bottom-color: transparent;

  ul {
    display: flex;
    flex-direction: column;

    li {
      background-color: #fffbf2;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      padding: 1.5rem;
      margin-bottom: 2rem;
      @include fontNormal(1.6rem, 2.4rem, rgba($black, 0.5));
      font-weight: 500;

      h4 {
        svg {
          path {
            fill: rgba($black,0.5);
          }
        }
      }

      &.active {
        color: $black;

        h4 {
          svg {
            path {
              fill: $black;
            }
          }
        }
      }
    }
  }
}
