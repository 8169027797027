.ms {
    margin-top:2rem ;
    margin-bottom:2rem ;
}

.my {
    margin-top:2rem;
    margin-bottom:2rem;
}

.mb-xs {
    margin-bottom:1rem ;
}

.mb-sm {
    margin-bottom:2rem ;
}

.mb-md {
    margin-bottom:3rem ;
}

.mb-lg {
    margin-bottom:4rem ;
}

.mb-xl {
    margin-bottom:5rem ;
}

.mt-xs {
    margin-top:1rem ;
}

.mt-sm {
    margin-top:2rem ;
}

.mt-xl {
    margin-top:5rem ;
}

.mr-sm {
    margin-right:2rem ;
}

.mr-xs {
    margin-right:1rem ;
}

.center {
    display:block;
    text-align:center;
}

.bold {
    font-weight:700;
}


p.active {
		background: rgba(3, 239, 98, 0.05);
        border-radius: 4px;
        padding:6px;
}

//Colors
.black {color:$black;}  
.green {color:$green;}  
.blue {color:$primary;}  
.primary {color:$primary;}  
.white {color:$white;}  
.nearwhite {color:$nearWhite;}  
.grey-7 {color:rgba($black,0.7);}
.grey-5 {color:rgba($black,0.5);}
.grey-3 {color:rgba($black,0.3);}
.red {color: #E61A1A;}


.grey-c {color:$grey-c;}  
.grey-6 {color:$grey-6;}  
.grey-9 {color:$grey-9;}  
.grey-3{color:$grey-3;}  


