// Auth
.formcol {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  label {
    @include fontNormal(1.4rem, 2.1rem, $black);
    font-weight: 600;
    margin-bottom: 5px;
  }

  p.error {
    @include fontNormal(1.4rem, 1.6rem, orangered);
  }

  .formgroup {
    background: #ffffff;
    border: 2px solid rgba(5, 25, 45, 0.3);
    display: flex;
    padding: 1.5rem;
    margin-bottom: 1rem;
    align-items: center;

    figure {
      margin-right: 1rem;
    }

    input {
      background: transparent;
      border: none;
      outline: none;
      // padding:1rem 0;
      transition: all 0.2s;
      @include fontNormal(1.6rem, 2rem, $grey-3);

      &:focus {
        outline: none;

        &:invalid {
          outline: none;
          border-bottom: 1px solid orangered;
        }
      }

      &::-webkit-input-placeholder {
        @include fontNormal(1.6rem, 2rem, $grey-3);
      }
    }
  }
}

.selectCol {
  margin-bottom: 1rem;

  label {
    @include fontNormal(1.6rem, 2.4rem, rgba($black, 0.5));
    margin-bottom: 1rem;
  }

  select {
    @include fontNormal(1.6rem, 2rem, $grey-3);
    background: #ffffff;
    border: 1px solid rgba(5, 25, 45, 0.5);
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 1.5rem;
    padding: 0.8rem 1rem;
    width: 100%;
    height: 4rem;
    display: inline-block;
    margin-top: 1rem;
    margin-bottom: 2rem;

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    &:-webkit-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    option {
      padding: 1rem;
      background-color: transparent;
      @include fontNormal(1.6rem, 2rem, $grey-3);
      cursor: pointer;
    }
  }
}

//Auth

// Search INput
.search__input {
  width: 100%;
  position: relative;
  background: $white;
  border: 0.3px solid rgba(2, 8, 14, 0.3);
  border-radius: 4px;
  padding: 1.2rem 1.8rem;
  display: flex;

  svg {
    margin-right: 2rem;
  }

  input {
    flex-grow: 1;
    background: transparent;
    border: none;
    outline: none;
    // padding:1rem 0;
    transition: all 0.2s;
    @include fontNormal(1.6rem, 2.4rem, $grey-3);

    &:focus {
      outline: none;

      &:invalid {
        outline: none;
        border-bottom: 1px solid orangered;
      }
    }

    &::-webkit-input-placeholder {
      @include fontNormal(1.6rem, 2.4rem, rgba($black, 0.3));
    }
  }
}
// Search Input

// Normal Input
.formCol {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  label {
    @include fontNormal(1.4rem, 2.1rem, rgba($black, 0.5));
    font-weight: 600;
    margin-bottom: 5px;
  }

  p.error {
    @include fontNormal(1.4rem, 1.6rem, orangered);
  }

  input,
  textarea {
    background: #ffffff;
    border: 2px solid rgba(2, 8, 14, 0.3);
    border-radius: 4px;
    outline: none;
    padding: 1rem;
    transition: all 0.2s;
    @include fontNormal(1.6rem, 2rem, rgba($black, 0.5));

    &:focus {
      outline: none;

      &:invalid {
        outline: none;
        border-bottom: 1px solid orangered;
      }
    }

    &::-webkit-input-placeholder {
      @include fontNormal(1.6rem, 2rem, rgba($black, 0.5));
    }
  }
}
// Normal Input
