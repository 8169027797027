@import './config';
@import './root';
@import './typography';
@import './animations';
@import './util';
@import '../components/button';
@import '../components/input';
@import '../components/pills';


* {
    transition: all .2s;
}

a {
    text-decoration: none;
}

 p.error {
    @include fontNormal(1.4rem, 1.6rem, orangered);
  }

  
ul{
    list-style:none;
    margin:0;
    padding:0;
}

img{
    max-width: 100%;
    height: auto;
}

button {
    cursor: pointer;
}

body {
    font-family: $primaryFont;
    font-weight:300;
    line-height:1.6rem;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items:center;
}

.col {
    display: flex;
    flex-direction: column;
}

.main {
    min-height:100vh;
}

button {
    border:none;
    outline:none;
    background-color: transparent;
}

* {
    transition: all .2s;
}

.d-flex {
    display:flex;;
}

.align-center {
    align-items: center;
}

.justify-between {
    justify-content:space-between;
}

.w-100 {
    width:100%;
}

.pagination {
    @include flexCenter;
    width:100%;

    p {
        margin-right:2rem;
        margin-left:2rem;
    }
}

.navigator {
    width:20rem;
    @include fontNormal(2rem,3rem,$black);
    font-weight: 500;
}

.seemore {
    	@include fontNormal(1.6rem,2rem,rgba($black,0.8));
        font-weight: 600;

        &:hover {
            color:$black;
        }
}