
/*--------------------------
    typography
---------------------------*/

@font-face {
  font-family: 'Montserrat Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-Bold.ttf') format('ttf');
}



@font-face {
  font-family: 'Montserrat', sans-serif;;
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-Regular.ttf') format('ttf');
}


html {
  box-sizing: border-box;
  font-size: 62.5%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}

@media only screen and (max-width: 37.5em) {
  html {
    font-size: 50%;
  }
}

h1,h2,h3,h4,h5,h6 {
  margin:0;
  padding:0;
}

body {
    font-family:$primaryFont;
    font-weight:300;
    line-height:1.6rem;
}

.h-1 {
  @include fontBold(3.6rem,4.6rem,$black);
  font-weight: 700;
}

//Text Styles

.text-header {
  @include fontBold(3rem,4.5rem,$grey-4);
}

.text-subheadng {
  @include fontNormal(3rem,4.5rem,$grey-4);
  font-weight: 600;
}

.text-subheading {
  @include fontNormal(3rem,4.5rem,$grey-4);
  font-weight: 600;
}


.text-mobile {
  @include fontNormal(2rem,2.5rem,$grey-4);
  font-weight: 600;
}

.text-big-mobile {
  @include fontNormal(2.4rem,3.6rem,$grey-4);
  font-weight: 600;
}

.text-body {
  @include fontNormal(1.6rem,2.4rem,$grey-4);
  font-weight: 500;
}

.text-body-mobile {
  @include fontNormal(1.4rem,2.1rem,$grey-4);
  font-weight: 600;
}

.text-sub {
  @include fontNormal(1.4rem,1.7rem,$grey-4);
  font-weight: 500;
}

.text-sec {
  @include fontNormal(2rem,3rem,$grey-4);
  font-weight: 600;
}

.text-ter {
  @include fontNormal(1.2rem,1.5rem,$grey-4);
  font-weight: 600;
}

span.testtakers {
  @include flexCenter;
  width: 3.5rem;
  height: 3.5rem;
  background: $green;
  border-radius: 50%;
  color:$primary;
}


span.facilitators {
  @include flexCenter;
    width: 3.5rem;
    height: 3.5rem;
    background: $primary;
    border-radius: 50%;
    color:$green;
}