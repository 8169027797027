.btn {
    @include btnGeneric;
    color:$white;
    @include flexCenter;
    @include fontNormal(1.6rem,2.4rem,$primary);
    padding: 1.2rem 4rem;
    
    &-blue {
        background-color: $primary;

        &:hover {
            background-color:rgba($primary,0.7);
         }
    }

    &-green {
        background-color: $green;
        color:$primary;

        &:hover {
            background-color:rgba($green,0.7);
         }
    }

    &-light {
        background-color: #E3F6F2;
        color:$primary;

        &:hover {
            background-color:rgba($green,0.7);
         }
    }

    &-transparent {
        background-color: transparent;
        color:$green;

        &:hover {
            background-color:rgba($primary,0.7);
         }
    }

    &-dim {
        background-color: rgba(2, 8, 14, 0.05);
        border: 0.5px solid rgba(2, 8, 14, 0.3);
        color:$primary;

        &:hover {
            background-color:rgba($primary,0.7);
         }
    }


}