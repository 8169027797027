@import './styles/abstract/config';
@import './styles/abstract/root';
@import './styles/abstract/typography';
@import './styles/abstract/base';


body {
  margin: 0;
  scroll-behavior: smooth;
  font-family:$primaryFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes fadeIn {
  0% {
      transform:scale(0.8);
      opacity:0;
  }
  100% {
      transform:scale(1);
      opacity:1;
  }
}

main,
.container {
  min-height: 10rem;
  position: relative;
}

.page {
    position:absolute;
    top:100px;
    left:0;
    right:0;
}

.fade-appear,
.fade-enter {
    opacity:0;
    z-index:1;
}


  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }